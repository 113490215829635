#loadingBg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: #f6e214;
    opacity: 1;
    z-index: 1000;
    transition: opacity ease-in .6s;
    pointer-events: none;
}

.wf-active #loadingBg {
    opacity: 0;
    pointer-events: none;
}

#loadingBg .loading-img  {
    width: 100px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}